import React, { useState, useMemo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import { db } from "./firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faClock,
  faCalendarAlt,
  faLocationDot,
  faUser,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";

const fetchServices = async (startDate, endDate) => {
  const servicesCollection = collection(db, "services");
  const servicesSnapshot = await getDocs(servicesCollection);
  const servicesList = servicesSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  // Only filter by date if both dates are selected
  if (startDate && endDate) {
    return servicesList.filter((service) => {
      const serviceDate = new Date(service.date.seconds * 1000);

      // For same-day selection, compare only the date parts
      if (startDate.toDateString() === endDate.toDateString()) {
        return serviceDate.toDateString() === startDate.toDateString();
      }

      // For date ranges, use the existing comparison
      return serviceDate >= startDate && serviceDate <= endDate;
    });
  }

  // Return all services if no date range is selected
  return servicesList;
};

function InfoItem({ icon, label, value }) {
  return (
    <div className="flex items-center">
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className="w-4 h-4 text-gray-400 mr-3"
          size="sm"
        />
      )}
      <div>
        <p className="text-sm text-gray-500">{label}</p>
        <p className="text-base font-medium text-gray-900">{value}</p>
      </div>
    </div>
  );
}

function ServiceDetails({ service, onClose, userRole }) {
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [isLoadingEmployee, setIsLoadingEmployee] = useState(true);

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const employeeRef = doc(db, "users", service.employee);
        const employeeDoc = await getDoc(employeeRef);

        if (employeeDoc.exists()) {
          setEmployeeDetails(employeeDoc.data());
        }
      } catch (error) {
        console.error("Error fetching employee details:", error);
      } finally {
        setIsLoadingEmployee(false);
      }
    };

    if (service.employee) {
      fetchEmployeeDetails();
    }
  }, [service.employee]);

  if (!service) {
    return null;
  }

  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString();
  };

  const formatSubmissionDateTime = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleString([], {
      dateStyle: "medium",
      timeStyle: "short",
    });
  };

  const getEmployeeDisplayName = () => {
    if (isLoadingEmployee) return "Loading...";
    if (!employeeDetails) return "N/A";
    return `${employeeDetails.firstName} ${employeeDetails.lastName}`;
  };

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      onClick={onClose}
    >
      <div
        className="relative top-20 mx-auto p-8 border w-11/12 md:w-3/4 lg:w-2/3 shadow-lg rounded-lg bg-white"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header with Service Name and Status */}
        <div className="flex justify-between items-center mb-8">
          <div>
            <h2 className="text-3xl font-bold text-gray-900">
              {service.serviceName}
            </h2>
            <span
              className={`mt-2 inline-block px-3 py-1 rounded-full text-sm font-semibold
              ${
                service.status === "completed"
                  ? "bg-green-100 text-green-800"
                  : service.status === "in-progress"
                  ? "bg-blue-100 text-blue-800"
                  : service.status === "approved"
                  ? "bg-green-100 text-green-800"
                  : "bg-yellow-100 text-yellow-800"
              }`}
            >
              <FontAwesomeIcon icon={faCircle} className="w-2 h-2 mr-2" />
              {service.status.charAt(0).toUpperCase() + service.status.slice(1)}
            </span>
          </div>
        </div>

        {/* Pricing Card - Only show for owner/admin */}
        {(userRole === "owner" || userRole === "admin") && (
          <div className="bg-blue-50 p-6 rounded-lg shadow-sm mb-8">
            <h3 className="text-lg font-semibold text-blue-900 mb-4">
              Pricing Information
            </h3>
            <div className="grid grid-cols-2 gap-8">
              <div>
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={faDollarSign}
                    className="w-4 h-4 text-blue-700 mr-2"
                  />
                  <p className="text-sm text-blue-700">Retail Price</p>
                </div>
                <p className="text-3xl font-bold text-blue-900">
                  ${service.retailPrice.toFixed(2)}
                </p>
              </div>
              <div>
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={faDollarSign}
                    className="w-4 h-4 text-blue-700 mr-2"
                  />
                  <p className="text-sm text-blue-700">Provider Rate</p>
                </div>
                <p className="text-3xl font-bold text-blue-900">
                  ${service.providerRate.toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Service Details */}
          <div className="bg-gray-50 p-6 rounded-lg shadow-sm">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">
              Service Details
            </h3>
            <div className="space-y-4">
              <InfoItem
                icon={faLocationDot}
                label="Location"
                value={service.location}
              />
              <InfoItem
                icon={faUser}
                label="Supervisor"
                value={service.supervisorName}
              />
              <InfoItem
                icon={faCalendarAlt}
                label="Service Date"
                value={formatDate(service.date)}
              />
            </div>
          </div>

          {/* Additional Information */}
          <div className="bg-gray-50 p-6 rounded-lg shadow-sm">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">
              Additional Information
            </h3>
            <div className="space-y-4">
              <InfoItem
                icon={faUser}
                label="Employee"
                value={getEmployeeDisplayName()}
              />
              <InfoItem
                icon={faClock}
                label="Submission Time"
                value={formatSubmissionDateTime(service.timeOfSubmission)}
              />
            </div>
          </div>

          {/* Add Approval Information */}
          {service.approvalTimestamp && (
            <div className="bg-green-50 p-6 rounded-lg shadow-sm">
              <h3 className="text-lg font-semibold text-green-800 mb-4">
                Approval Information
              </h3>
              <div className="space-y-4">
                <InfoItem
                  icon={faUser}
                  label="Approved By"
                  value={service.approvedByName || 'N/A'}
                />
                <InfoItem
                  icon={faClock}
                  label="Approval Time"
                  value={formatSubmissionDateTime(service.approvalTimestamp)}
                />
              </div>
            </div>
          )}

          {/* Add Last Edit Information */}
          {service.lastEditTimestamp && (
            <div className="bg-gray-50 p-6 rounded-lg shadow-sm">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">
                Last Edit Information
              </h3>
              <div className="space-y-4">
                <InfoItem
                  icon={faUser}
                  label="Edited By"
                  value={service.lastEditByName || 'N/A'}
                />
                <InfoItem
                  icon={faClock}
                  label="Edit Time"
                  value={formatSubmissionDateTime(service.lastEditTimestamp)}
                />
              </div>
            </div>
          )}
        </div>

        {/* Add Notes Section */}
        <div className="mt-8 bg-gray-50 p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">Notes</h3>
          <p className="text-gray-700 whitespace-pre-wrap">
            {service.notes || "No notes provided"}
          </p>
        </div>

        <div className="mt-8 text-center">
          <button
            className="px-6 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-colors"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

// Add loading state for the table
function TableSkeleton() {
  return (
    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
      <div className="bg-gray-100 border-b-2 border-gray-200">
        <div className="px-5 py-3 flex space-x-4">
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <Skeleton
              key={i}
              height={16}
              width={100}
              baseColor="#E5E7EB"
              highlightColor="#F3F4F6"
            />
          ))}
        </div>
      </div>
      <div className="bg-white">
        {[1, 2, 3, 4, 5].map((row) => (
          <div
            key={row}
            className="px-5 py-5 border-b border-gray-200 flex space-x-4"
          >
            {[1, 2, 3, 4, 5, 6].map((col) => (
              <Skeleton
                key={col}
                height={16}
                width={100}
                baseColor="#F9FAFB"
                highlightColor="#F3F4F6"
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

function Services({ userRole }) {
  const [searchParams] = useSearchParams();
  const [selectedService, setSelectedService] = useState(null);
  const [sorting, setSorting] = useState([
    {
      id: "Submission Date",
      desc: true,
    },
  ]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [searchText, setSearchText] = useState("");

  const {
    data: services,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["services", startDate, endDate],
    queryFn: () => fetchServices(startDate, endDate),
    enabled: true,
  });

  useEffect(() => {
    const serviceId = searchParams.get("id");
    if (serviceId && services) {
      const service = services.find((s) => s.id === serviceId);
      if (service) {
        setSelectedService(service);
      }
    }
  }, [searchParams, services]);

  const handleViewDetails = (service) => {
    setSelectedService(service);
  };

  const filteredServices = useMemo(() => {
    if (!services) return [];

    return services.filter((service) => {
      if (!searchText || searchText === "") return true;

      const searchTerms = searchText.toLowerCase().split(/\s+/).filter(Boolean);

      return searchTerms.some((term) => {
        const location = (service.location || "").toLowerCase();
        const supervisor = (service.supervisorName || "").toLowerCase();
        const description = (service.serviceDescription || "").toLowerCase();

        return (
          location.includes(term) ||
          supervisor.includes(term) ||
          description.includes(term)
        );
      });
    });
  }, [services, searchText]);

  const columns = useMemo(
    () => [
      {
        header: "Status",
        accessorKey: "status",
        cell: ({ getValue }) => {
          const status = getValue();
          const getStatusColor = (status) => {
            switch (status) {
              case "completed":
                return "bg-green-100 text-green-800";
              case "in-progress":
                return "bg-blue-100 text-blue-800";
              case "pending":
                return "bg-yellow-100 text-yellow-800";
              case "approved":
                return "bg-green-100 text-green-800";
              default:
                return "bg-gray-100 text-gray-800";
            }
          };

          return (
            <span
              className={`px-2 py-1 rounded-full text-xs font-semibold ${getStatusColor(
                status
              )}`}
            >
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </span>
          );
        },
      },
      {
        header: "Service",
        accessorKey: "serviceName",
      },
      {
        header: "Date",
        accessorFn: (row) =>
          new Date(row.date.seconds * 1000).toLocaleDateString(),
      },
      {
        header: "Supervisor",
        accessorKey: "supervisorName",
      },
      {
        header: "Location",
        accessorKey: "location",
      },
      // Only include price-related columns for owner/admin
      ...(userRole === "owner" || userRole === "admin"
        ? [
            {
              header: "Price",
              accessorKey: "retailPrice",
              cell: (info) => `$${info.getValue().toFixed(2)}`,
            },
            {
              header: "Provider Rate",
              accessorKey: "providerRate",
              cell: (info) => `$${info.getValue().toFixed(2)}`,
            },
          ]
        : []),
      {
        header: "Approved By",
        accessorKey: "approvedByName",
        cell: (info) => info.getValue() || "—",
      },
      {
        header: "Last Edit",
        accessorFn: (row) =>
          row.lastEditTimestamp
            ? new Date(row.lastEditTimestamp.seconds * 1000).toLocaleString([], {
                dateStyle: "short",
                timeStyle: "short",
              })
            : "—",
      },
      // Actions column (already correctly handled)
      ...(userRole === "owner" || userRole === "consultant"
        ? [
            {
              header: "Actions",
              cell: ({ row }) => (
                <button
                  onClick={() => handleViewDetails(row.original)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                >
                  View Details
                </button>
              ),
            },
          ]
        : []),
    ],
    [userRole]
  );

  const table = useReactTable({
    data: filteredServices || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <Skeleton
            height={24}
            width={200}
            className="mb-6"
            baseColor="#374151"
            highlightColor="#4B5563"
          />

          <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
            <div className="flex flex-row items-end space-x-4">
              <div className="flex flex-col gap-2">
                <Skeleton
                  height={16}
                  width={80}
                  className="mb-2"
                  baseColor="#374151"
                  highlightColor="#4B5563"
                />
                <Skeleton
                  height={40}
                  width={288}
                  baseColor="#374151"
                  highlightColor="#4B5563"
                />
              </div>
              <div className="flex flex-col gap-2 flex-1">
                <Skeleton
                  height={16}
                  width={80}
                  className="mb-2"
                  baseColor="#374151"
                  highlightColor="#4B5563"
                />
                <Skeleton
                  height={40}
                  baseColor="#374151"
                  highlightColor="#4B5563"
                />
              </div>
            </div>
          </div>

          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <TableSkeleton />
          </div>
        </div>
      </div>
    );
  }

  if (error)
    return (
      <div className="text-red-500 text-center">Error: {error.message}</div>
    );

  return (
    <div className="container mx-auto px-4 sm:px-8">
      <div className="py-8">
        <div>
          <h1 className="text-lg font-bold text-white uppercase tracking-wider">
            Services Report
          </h1>
        </div>

        <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
          <div className="flex flex-row items-end space-x-4">
            <div className="flex flex-col gap-2">
              <label className="text-sm font-semibold text-gray-300">
                Date Range
              </label>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                className="w-72 p-3 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                placeholderText="Select date range"
                dateFormat="MMM dd, yyyy"
                required
              />
            </div>

            <div className="flex flex-col gap-2 flex-1">
              <label className="text-sm font-semibold text-gray-300">
                Search
              </label>
              <input
                type="text"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search location, supervisor, or description..."
                className="w-full p-3 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
              />
            </div>
          </div>
        </div>

        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        <span>
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted()] ?? null}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {selectedService && (
        <ServiceDetails
          service={selectedService}
          onClose={() => setSelectedService(null)}
          userRole={userRole}
        />
      )}
    </div>
  );
}

export default Services;
