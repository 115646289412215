export const getRoleBasedPath = (role, applicationComplete) => {
  if (role === "supervisor") {
    return "/dashboard/vincollect";
  }
  
  if (["owner", "manager", "admin", "director", "hr", "consultant", "recruiter"].includes(role)) {
    return "/dashboard";
  }
  
  if (["employee", "mobile"].includes(role) && applicationComplete === false) {
    return "/complete-application";
  }
  
  return "/login";
}; 