import React, { useEffect, useState } from "react";
import { auth, db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import Login from "./Login";
import Dashboard from "./Dashboard";
import ApplicationForm from "./ApplicationForm";
import EmployeeInterview from "./EmployeeInterview";
import CompleteApplication from "./CompleteApplication";
import NonCompeteAgreement from "./NonCompeteAgreement";
import UniquePunch from "./UniquePunch";
import PasswordReset from "./PasswordReset";
import { UserProvider } from "./UserContext";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import InspectionForm from "./InspectionForm";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FormProvider } from "./FormContext";
import { GoogleMapsProvider } from "./GoogleMapsProvider";
import { getRoleBasedPath } from "./utils/roleNavigation";
import { motion } from "framer-motion";

const queryClient = new QueryClient();

function LoadingScreen() {
  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="text-center"
      >
        <motion.div
          animate={{ 
            scale: [1, 1.2, 1],
            rotate: [0, 360]
          }}
          transition={{ 
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
          className="text-5xl sm:text-6xl md:text-7xl audiowide mb-4 text-white"
          style={{
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8), 0 0 10px rgba(0, 0, 0, 0.5)",
          }}
        >
          UNIQUE
        </motion.div>
        <div className="text-blue-400">Loading...</div>
      </motion.div>
    </div>
  );
}

function App() {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [applicationComplete] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (userAuth) => {
      try {
        if (userAuth) {
          const userDoc = await getDoc(doc(db, "users", userAuth.uid));
          if (userDoc.exists()) {
            setRole(userDoc.data().role);
          } else {
            console.error("User data not found. Please contact your administrator.");
          }
          setUser(userAuth);
        } else {
          setUser(null);
          setRole(null);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  function RenderRoutes() {
    let navigate = useNavigate();

    useEffect(() => {
      if (applicationComplete && window.location.pathname.includes("/complete-application")) {
        navigate("/dashboard");
      }
    }, [navigate]);

    return (
      <UserProvider>
        <Routes>
          <Route path="/login" element={!user ? <Login /> : <Navigate to={getRoleBasedPath(role)} />} />
          <Route
            path="/dashboard/*"
            element={
              user && (getRoleBasedPath(role) === "/dashboard" || getRoleBasedPath(role) === "/dashboard/vincollect") ? (
                <Dashboard userRole={role} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/auth/action" element={<PasswordReset />} />
          <Route
            path="/apply"
            element={
              <FormProvider>
                <ApplicationForm />
              </FormProvider>
            }
          />
          <Route path="/inspection" element={<InspectionForm />} />
          <Route
            path="/non-compete-agreement"
            element={<NonCompeteAgreement />}
          />
          <Route path="/unique-punch" element={<UniquePunch />} />
          <Route
            path="/complete-application/:uid"
            element={<CompleteApplication />}
          />
          <Route path="/interview/:id" element={<EmployeeInterview />} />
          <Route path="/*" element={<Navigate to="/login" />} />
        </Routes>
      </UserProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <GoogleMapsProvider>
        <Router>
          <div className="App">
            <RenderRoutes />
          </div>
        </Router>
      </GoogleMapsProvider>
    </QueryClientProvider>
  );
}

export default App;
